/* DEPENDENCIES */
import React, { useEffect, useState, Suspense, lazy } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { Provider } from 'react-redux';
/* STORE */
import Store from './reducers';
/* COMPONENTS */
import AppContainer from './components/AppContainer';
import checkRole from './utils/checkRole';
const Error403 = lazy(() => import('./components/assets/Error403'));
const Error404 = lazy(() => import('./components/assets/Error404'));
const Admins = lazy(() => import('./components/AdminUser'));
const AdminsEdit = lazy(() => import('./components/AdminUser.Edit'));
const Scans = lazy(() => import('./components/Scans'));
const ScansByShop = lazy(() => import('./components/ScansByShop'));
const ScanHistory = lazy(() => import('./components/ScanHistory'));
const Articles = lazy(() => import('./components/Articles'));
const ArticlesDetail = lazy(() => import('./components/Articles.Detail'));
const BarCodeScanner = lazy(() => import('./components/BarCodeScanner'));
const Dashboard = lazy(() => import('./components/Dashboard'));
const Descriptions = lazy(() => import('./components/Descriptions'));
const Destinations = lazy(() => import('./components/Destinations'));
const DestinationsEdit = lazy(() => import('./components/Destinations.Edit'));
const ExportImages = lazy(() => import('./components/ExportImages'));
const Images = lazy(() => import('./components/Images'));
const ImagesErrors = lazy(() => import('./components/Images.Errors'));
const Loader = lazy(() => import('./components/assets/Loader'));
const Login = lazy(() => import('./components/Login'));
const LoginTFA = lazy(() => import('./components/Login.TFA'));
const Profile = lazy(() => import('./components/Profile'));
const Promos = lazy(() => import('./components/Promos'));
const PromosEdit = lazy(() => import('./components/Promos.Edit'));
const PromosProducts = lazy(() => import('./components/Promos.Products'));
const PromosProcess = lazy(() => import('./components/Promos.Process'));
const Settings = lazy(() => import('./components/Settings'));
const SettingsEdit = lazy(() => import('./components/Settings.Edit'));
const TFAEnable = lazy(() => import('./components/TFA.Enable'));
const Uploads = lazy(() => import('./components/Uploads'));
const UploadsErrors = lazy(() => import('./components/Uploads.Errors'));

const App = () => {
    const [auth, setAuth] = useState(Store.getState().auth.isAuthenticated);
    const [redirect] = useState(
        window.location.pathname + window.location.search,
    );

    useEffect(() => {
        const login = Store.subscribe(() => {
            setAuth(Store.getState().auth.isAuthenticated);
        });

        return function cleanup() {
            login();
        };
    }, []);

    return (
        <Provider store={Store}>
            <Loader />
            {auth ? (
                <AppContainer>
                    <Suspense fallback={<div className="loader"> <div className="typing_loader"></div> </div>} >
                        <Routes>
                            <Route path="/" element={<Dashboard />} />
                            <Route path="/analytics/scan-logs" element={<Scans />} />
                            <Route path="/analytics/scans-by-shop" element={<ScansByShop />} /> <Route path="/analytics/scans-by-code" element={<ScanHistory />} />
                            <Route path="/admin-users" element={<Admins />} />
                            <Route path="/articles" element={checkRole('articles_read') ? (<Articles />) : (<Error403 title={'Lista de articulos'} perm={'articles_read'} />)} />
                            <Route path="/articles/:sku" element={checkRole(['articles_read', 'articles_write']) ? (<ArticlesDetail />) : (<Error403 title={'Detalle de Articulo'} perm={'articles_read'} />)} />
                            <Route path="/admin-users/create" element={checkRole('admins_write') ? (<AdminsEdit />) : (<Error403 title={'Editar Admin'} perm={'admins_write'} />)} />
                            <Route path="/admin-users/edit/:id" element={checkRole('admins_write') ? (<AdminsEdit />) : (<Error403 title={'Editar Admin'} perm={'admins_write'} />)} />
                            <Route path="/descriptions" element={checkRole('articles_read') ? (<Descriptions />) : (<Error403 title={'Descripciones'} perm={'articles_read'} />)} />
                            <Route path="/destinations" element={checkRole('settings_read') ? (<Destinations />) : (<Error403 title={'Destinos'} perm={'settings_read'} />)} />
                            <Route path="/destinations/:id" element={checkRole('settings_read') ? (<DestinationsEdit />) : (<Error403 title={'Destinos'} perm={'settings_read'} />)} />
                            <Route path="/export" element={checkRole('uploads_export') ? (<ExportImages />) : (<Error403 title={'Exportar imagenes'} perm={'uploads_export'} />)} />
                            <Route path="/images" element={checkRole('images_read') ? (<Images />) : (<Error403 title={'Imagenes'} perm={'images_read'} />)} />
                            <Route path="/images/errors" element={checkRole('images_read') ? (<ImagesErrors />) : (<Error403 title={'Errores en Imagenes'} perm={'images_read'} />)} />
                            <Route path="/profile" element={<Profile />} />
                            <Route path="/promos" element={checkRole('promos_read') ? (<Promos />) : (<Error403 title={'Listado de Promociones'} perm={'promos_read'} />)} />
                            <Route path="/promos/process" element={checkRole('promos_write') ? (<PromosProcess />) : (<Error403 title={'Procesar Archivo de Promociones'} perm={'promos_write'} />)} />
                            <Route path="/promos/edit/:id" element={checkRole('promos_write') ? (<PromosEdit />) : (<Error403 title={'Editar Promoción'} perm={'promos_write'} />)} />
                            <Route path="/promos/create" element={checkRole('promos_write') ? (<PromosEdit />) : (<Error403 title={'Crear Promoción'} perm={'promos_write'} />)} />
                            <Route path="/promos/products/:id" element={checkRole('promos_write') ? (<PromosProducts />) : (<Error403 title={'Crear Promoción'} perm={'promos_write'} />)} />
                            <Route path="/settings" element={checkRole('settings_read') ? (<Settings />) : (<Error403 title={'Opciones'} perm={'settings_read'} />)} />
                            <Route path="/scanner" element={checkRole('barcode_scan') ? (<BarCodeScanner />) : (<Error403 title={'Escanear codigo de barras'} perm={'barcode_scan'} />)} />
                            <Route path="/settings/edit/:key" element={checkRole('settings_read') ? (<SettingsEdit />) : (<Error403 title={'Editar Opciones'} perm={'settings_read'} />)} />
                            <Route path="/tfa/enable" element={<TFAEnable />} />
                            <Route path="/uploads" element={checkRole('uploads_read') ? (<Uploads />) : (<Error403 title={'Subidas'} perm={'uploads_read'} />)} />
                            <Route path="/uploads/errors" element={checkRole('uploads_read') ? (<UploadsErrors />) : (<Error403 title={'Errores en Subidas'} perm={'uploads_read'} />)} />
                            <Route path="/login" element={<Login />} />
                            <Route path="/login/tfa" element={<LoginTFA />} />
                            <Route path="*" element={<Error404 />} />
                        </Routes>
                    </Suspense>
                </AppContainer>
            ) : (
                <Routes>
                    <Route path="/login" element={<Login />} />
                    <Route path="/login/tfa" element={<LoginTFA />} />
                    <Route path="/*" element={<Navigate to={`/login${redirect && redirect.indexOf('login') === -1 && redirect !== '/' ? `?path=${redirect}` : ''}`} />} />
                </Routes>
            )}
        </Provider>
    );
};

export default App;
